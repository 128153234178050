import React, { useEffect, useState } from 'react'
import "./HomeBanner.css";
import Navbar from "../../Navbar/Navbar";
import banner from "../../../assets/images/slider-images/nabd-banner.webp"

import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";
import GetApiCall from '../../../GETAPI';
import Notiflix from 'notiflix';


function HomeBanner() {

  const [bannerList, setBannerList] = useState([])
  useEffect(() => {
    GetApiCall.getRequest("GetBannerList").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setBannerList(obj.data.filter((item) => item.status === "Active").sort((a, b) => a.sequence - b.sequence));

        } else {
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }, [])
  return (
    <>
      <section className='Main-slider d-lg-flex justify-content-lg-center align-items-lg-center position-relative'>
        <Navbar />
        {/* <div className='container mt-auto mb-4'>
          <div className='row justify-content-lg-center align-items-end'>
            <div className='col-lg-8 text-center'> */}
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          Keyboard={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          className='h-100 w-100'
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
        >
          {bannerList.map((data) => {
            let bannerDetail = data?.desktop
            if (bannerDetail !== null) {
              const bannerInfo = JSON.parse(bannerDetail)
              return (

                <SwiperSlide>
                  <img src={bannerInfo[0]?.FLD_Banner_URL}
                    alt={bannerInfo[0]?.FLD_Alt} className='img-fluid' />

                </SwiperSlide>
              )
            }
          })}
        </Swiper>
        {/* <div className='banner-content'>
          <p className='b-h2'>
            National
            Association For
            The Blind
            <br />
            (NAB DELHI)
          </p>
          <p className='b-h1'>
            80G | FCRA | CSR Preferred Partner | USA 501(c) (3) tax exemption | Guide Star
            Certified
          </p>
        </div> */}

        {/* </div>
          </div>

        </div> */}
      </section>
    </>
  );
}

export default HomeBanner;